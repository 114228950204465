import React, { ReactElement } from 'react'
import './ContactUsMobileNavigation.scss'
import Header from '../../components/Header/Header'
import VerticalNavigation from '../../components/VerticalNavigation/VerticalNavigation'
import CONTACT_US_NAVIGATION from '../../data/contact-us-navigation.json'
import Seo from '../../components/Seo/Seo'

const ContactUsMobileNavigation = (): ReactElement => {
  return (
    <>
      <Header />
      <Seo title="Contact Us | Navigation" />
      <div className="contact-us-mobile-navigation">
        <h2 className="contact-us-mobile-navigation-title">Contact Us</h2>
        <VerticalNavigation
          navigations={CONTACT_US_NAVIGATION}
          variant="secondary"
        />
      </div>
    </>
  )
}

export default ContactUsMobileNavigation
